import React from 'react';
import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';

export default function LazyLoadingIndicator() {
  return (
    <>
      <AutorenewTwoToneIcon color="secondary" className="rotating" />
      Loading View…
    </>
  );
}
