// Google
export const GOOGLE_SERVER_LOCATION = 'europe-west2';

// URL Paths
export const PATH_ROOT = '/';
export const PATH_HOME = '/home';
export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_SIGNEDIN = '/signedIn';
export const PATH_PROFILE = '/profile';
export const PATH_LIST = '/list';

// Firestore Paths
export const FIRESTORE_COLLECTION_FOLDER = 'folder';
export const FIRESTORE_COLLECTION_FILE = 'file';
export const FIRESTORE_COLLECTION_LOGGER = 'logger';

// Time periods
export const PERIOD_ONE_DAY = 24 * 60 * 60 * 1000;
export const PERIOD_EXPIRE_SIGNED_URL = 31;

// File
export const FILE_LOAD_LIMIT = 499;
export const FILE_MAX_SIZE = 5242880; // 5Mo
export const FILE_MAX_NUMBER = 200;

// CSV
export const EXPORT_PREFIX = 'exports';
export const EXPORT_FILE_FOLDERS = 'file_manager_folders_with_files.csv';

// Functions
export const HTTPS_CALLABLE = `https://${process.env.REACT_APP_FIREBASE_HTTPS_CALLABLE}`;
