import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Navigate, Route } from 'react-router-dom';
import Home from '../../../home/Home';
import {
  PATH_HOME,
  PATH_LIST,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_PROFILE,
  PATH_ROOT,
  PATH_SIGNEDIN,
} from '../../constants.alias';
import LazyLoadingIndicator from './LazyLoadingIndicator';
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import { useServiceWorkerStore } from '../../../serviceWorker/state';

const Login = React.lazy(() => import('../../../auth/Login'));
const Logout = React.lazy(() => import('../../../auth/Logout'));
const SignedIn = React.lazy(() => import('../../../auth/SignedIn'));
const UserProfile = React.lazy(() => import('../../../auth/UserProfile'));

const FolderList = React.lazy(() => import('../../../folder/FolderList'));
const FolderCreate = React.lazy(() => import('../../../folder/Create'));
const FolderEdit = React.lazy(() => import('../../../folder/Edit'));
const FolderDelete = React.lazy(() => import('../../../folder/Delete'));

const FileList = React.lazy(() => import('../../../file/FileList'));
const FileDelete = React.lazy(() => import('../../../file/Delete'));

export default function Router() {
  const setServiceWorkerInitialized = useServiceWorkerStore(
    (state) => state.setServiceWorkerInitialized,
  );
  const setServiceWorkerUpdated = useServiceWorkerStore((state) => state.setServiceWorkerUpdated);

  if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register({
      onSuccess: () => setServiceWorkerInitialized(),
      onUpdate: (reg) => setServiceWorkerUpdated(reg),
    });
  } else {
    serviceWorkerRegistration.unregister();
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LazyLoadingIndicator />}>
        <Routes>
          <Route path={PATH_HOME} element={<Home />} />
          <Route
            path={PATH_LOGIN}
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={PATH_LOGOUT}
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <Logout />
              </Suspense>
            }
          />
          <Route
            path={PATH_SIGNEDIN}
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <SignedIn />
              </Suspense>
            }
          />
          <Route
            path={PATH_PROFILE}
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <UserProfile />
              </Suspense>
            }
          />
          <Route
            path={PATH_LIST}
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <FolderList />
              </Suspense>
            }
          />
          <Route
            path="/folder/create"
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <FolderCreate />
              </Suspense>
            }
          />
          <Route
            path="/folder/edit/:folderId/:folderName"
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <FolderEdit />
              </Suspense>
            }
          />

          <Route
            path="/folder/delete/:folderId/:folderName"
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <FolderDelete />
              </Suspense>
            }
          />

          <Route
            path="/folder/:folderId/:folderName/file/"
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <FileList />
              </Suspense>
            }
          />

          <Route
            path="/folder/:folderId/:folderName/file/:fileId/:fileName"
            element={
              <Suspense fallback={<LazyLoadingIndicator />}>
                <FileDelete />
              </Suspense>
            }
          />

          <Route path={PATH_ROOT} element={<Navigate to={PATH_LIST} />} />

          <Route path="*" element={<Navigate to={PATH_LIST} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
