import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Router from './lib/ui/components/Router';
import fileManagerTheme from './lib/ui/theme';
import SWAlerts from './serviceWorker/SWAlerts';

export default function App() {
  return (
    <ThemeProvider theme={fileManagerTheme}>
      <Router />
      <SWAlerts />
    </ThemeProvider>
  );
}
