import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import './home.scss';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Typography,
  CardActions,
  Container,
} from '@mui/material';
import { useUserStore } from '../auth/state';
import { PATH_LIST } from '../lib/constants.alias';

export default function Home() {
  const currentUser = useUserStore((state) => state.currentUser);

  if (currentUser && currentUser.email) {
    return <Navigate to={PATH_LIST} />;
  }

  return (
    <Container className="App">
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" color="primary">
            File Manager
          </Typography>
          <Typography component="h2" variant="h5" color="secondary">
            Kora
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Photo gestionnaire de fichiers"
                width="328"
                height="250"
                image="/img/file-management.jpeg"
                title="Accès au gestionnaire de fichiers"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Gestion des fichiers
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Accédez à la liste des ressources disponibles.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Link to={PATH_LIST}>
                <Button size="large" variant="contained" color="primary">
                  Listes des ressources
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
