import React from 'react';
import { Alert, AlertTitle, Button, Snackbar } from '@mui/material';
import { useServiceWorkerStore } from './state';

export default function SWAlerts() {
  const swInitState = useServiceWorkerStore((state) => state.serviceWorkerInitialized);
  const swUpdated = useServiceWorkerStore((state) => state.serviceWorkerUpdated);
  const setServiceWorkerInitializedReset = useServiceWorkerStore(
    (state) => state.setServiceWorkerInitializedReset,
  );
  const setServiceWorkerUpdatedReload = useServiceWorkerStore(
    (state) => state.setServiceWorkerUpdatedReload,
  );

  const handleSnackbarClose = () => {
    setServiceWorkerInitializedReset();
  };
  const handleSWUpdateAlertButton = () => {
    setServiceWorkerUpdatedReload();
  };

  return (
    <>
      <Snackbar open={swInitState} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert severity="success">
          <AlertTitle>File Manager</AlertTitle>
          L&apos;application est chargée et mise en cache
        </Alert>
      </Snackbar>
      <Snackbar open={swUpdated} onClose={handleSWUpdateAlertButton}>
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={handleSWUpdateAlertButton}>
              Recharger
            </Button>
          }>
          <AlertTitle>Votre attention est requise</AlertTitle>
          Une nouvelle version du File Manager est disponible, rechargez la page pour en bénéficier.
        </Alert>
      </Snackbar>
    </>
  );
}
