import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: process.env.REACT_APP_PRIMARY_COLOR || '#ef531b',
    contrastText: '#ffffff',
  },
  secondary: { main: process.env.REACT_APP_SECONDARY_COLOR || '#304692' },
};
const fileManagerTheme = createTheme({ palette });
export default fileManagerTheme;
