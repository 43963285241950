import create, { SetState, GetState } from 'zustand';
import { ServiceWorkerStore } from './model';

export const useServiceWorkerStore = create<ServiceWorkerStore>(
  (set: SetState<ServiceWorkerStore>, get: GetState<ServiceWorkerStore>) => ({
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: {} as ServiceWorkerRegistration,
    setServiceWorkerInitialized: (): void => {
      set({ serviceWorkerInitialized: true });
    },
    setServiceWorkerInitializedReset: (): void => {
      set({ serviceWorkerInitialized: false });
    },
    setServiceWorkerUpdated: (reg: ServiceWorkerRegistration): void => {
      set({
        serviceWorkerUpdated: true,
        serviceWorkerRegistration: reg,
      });
    },
    setServiceWorkerUpdatedReload: (): void => {
      const registrationWaiting = get().serviceWorkerRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e) => {
          const sw = e && e.target ? (e.target as ServiceWorker) : null;
          if (sw?.state === 'activated') {
            window.location.reload();
          }
        });
      }
    },
  }),
);
