import create from 'zustand';
import { LocalUser, UserStore } from './model';

export const useUserStore = create<UserStore>((set) => ({
  currentUser: null,
  saveCurrentUser: (user: LocalUser) => {
    set(() => ({
      currentUser: user,
    }));
  },
  logoutCurrentUser: () => {
    set(() => ({
      currentUser: null,
    }));
  },
}));
