import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import moment from 'moment';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'moment/locale/fr';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

if (process.env.REACT_APP_STAGE === 'development') {
  reportWebVitals(console.log);
}
moment.locale('fr');
